import styled from 'styled-components';
import { Link } from 'gatsby';

import { Color } from '@lerna-monorepo/common/theme/color.constant';

import LocalArrowIcon from './images/arrow.inline.svg';

export const SearchLabel = styled.p`
  color: ${Color.veryLightGrey};
`;

export const SearchValue = styled.p``;

export const PageListSection = styled.div`
  padding: 100px 0 150px;
`;

export const ArrowIcon = styled(LocalArrowIcon)`
  width: 16px;
  padding-left: 23px;
  padding-right: 23px;
  flex-shrink: 0;
  g {
    fill: ${Color.primary};
  }
`;

export const PageListItem = styled.div`
  border: 1px solid ${Color.mediumLightGrey};
  width: 100%;
  min-height: 60px;
  margin-bottom: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const PageTitle = styled.p`
  padding: 10px;
  line-height: 18px;
`;

export const NavLink = styled(Link)`
  color: ${Color.black};
`;
